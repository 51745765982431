import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Helmet from 'react-helmet'

export default ({ data }) => {
  return (
    <Layout>
        <Helmet>
        <title>{`Amikom News Info`}</title>
        </Helmet>
      <div>
        <h4>Info</h4>
        {data.allInfo.edges.map(({ node }) => (
          <div key={node.id}>
            <Link
              to={node.fields.slug}>
              <h3>
                {node.title}
              </h3>
            </Link>
          </div>
        ))}
        <b><Link to={`/info`}>Semua Info</Link></b>
        <h4>News</h4>
        {data.allNews.edges.map(({ node }) => (
          <div key={node.id}>
            <Link
              to={node.fields.slug}>
              <h3>
                {node.title}
              </h3>
            </Link>
          </div>
        ))}
        <b><Link to={`/news`}>Semua Berita</Link></b>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allInfo(
      limit: 7
      sort: { fields: [item_id], order: DESC }) {
      totalCount
      edges {
        node {
          id
          title
          item_id
          fields {
            slug
          }
        }
      }
    }
    allNews(
      limit: 7
      sort: { fields: [item_id], order: DESC }) {
      totalCount
      edges {
        node {
          id
          title
          item_id
          fields {
            slug
          }
        }
      }
    }
  }
`
